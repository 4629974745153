html {
  overflow-y: overlay;
  position: fixed;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

input[id^='react-select-'] {
  opacity: 1 !important;
}

@keyframes jiggle {
  0% {
    transform: rotate(-2deg);
  }

  50% {
    transform: rotate(2deg);
  }
}

@-moz-keyframes jiggle {
  0% {
    -moz-transform: rotate(-2deg);
  }

  50% {
    -moz-transform: rotate(2deg);
  }
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: rotate(-2deg);
  }

  50% {
    -webkit-transform: rotate(2deg);
  }
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}